import React, { useState } from "react";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  Wrapper,
  NavigationBar,
  LogoMobile,
  MobileNavigationBar,
  DesktopNavigationWrapper,
  BackArrowWrapper,
  Title,
  Subtitle,
  SectionWrapper,
  SectionTitle,
  Description,
  HorizontalDivider,
  BackgroundWrapper,
  BlueRectangle,
  BackToTopArrowWrapper,
  FooterWrapper,
  ContactInformation,
  ContactTitle,
  ContactDescription,
  TransitionWrapper,
  TransitionRandomBlock,
} from "./style";
import Navigation from "../../common/Navigation";
import Breakpoint from "../../common/Breakpoint";
import { PageChange } from "../../common/Scroll";
import { getLanguage } from "../../../utils/i18n";
import { LocationProp, PageThemeType } from "../../../utils/types";
import ArrowLeft from "-!svg-react-loader!../../../assets/images/arrowLeft.svg";
import ArrowUp from "-!svg-react-loader!../../../assets/images/arrowUp.svg";

const goBackPath = "/career";
const navigationThemes = ["black"] as PageThemeType[];

const transitionRandomBlocks = Array.from(Array(6).keys()).map(c =>
  Array.from(Array(4).keys()).map(r => (
    <TransitionRandomBlock
      key={c + r}
      delay={Math.floor(Math.random() * (800 - 64 + 1)) + 64}
    />
  ))
);

interface OfferType {
  id: string;
  title: string;
  subtitle?: string;
  requirements: string;
  whatDoWeOffer: string;
  whoAreWe: string;
  apply: string;
}

interface DataType {
  pl: OfferType;
  en: OfferType;
}

interface OffersProps extends LocationProp {
  data: DataType;
}

const Offers: React.FC<OffersProps> = ({ location, data }) => {
  const [, setModalState] = useState<boolean>(false);
  const [pageChange, setPageChange] = useState<PageChange>();

  const { t } = useTranslation("career");
  const i18nContact = useTranslation("contact");
  const mainLanguage = getLanguage() === "en" ? "en" : "pl";
  const secondLanguage = mainLanguage === "en" ? "pl" : "en";
  const {
    title,
    subtitle,
    requirements,
    whatDoWeOffer,
    whoAreWe,
    apply,
  } = data[mainLanguage];
  const {
    title: titleAlternative,
    subtitle: subtitleAlternative,
    requirements: requirementsAlternative,
    whatDoWeOffer: whatDoWeOfferAlternative,
    whoAreWe: whoAreWeAlternative,
    apply: applyAlternative,
  } = data[secondLanguage];

  const replaceHTMLLineSeparator = (html: string) =>
  html? html.replace(/\u2028/g, " ") : '';

  const goBack = () => {
    setPageChange("next");
    setTimeout(() => {
      navigate(goBackPath);
    }, 800);
  };

  return data.en || data.pl ? (
    <>
      {/* <NavigationBar>
        <LogoMobile /> */}
        {/* <MobileNavigationBar /> */}
        <Navigation
          setModalState={setModalState}
          setPageChange={setPageChange}
          navigationThemes={navigationThemes}
          location={location}
          theme="light"
        />
      {/* </NavigationBar> */}
      <DesktopNavigationWrapper>
        <Navigation
          setModalState={setModalState}
          setPageChange={setPageChange}
          navigationThemes={navigationThemes}
          location={location}
          theme="light"
        />
      </DesktopNavigationWrapper>
      <Wrapper>
        <BackArrowWrapper onClick={goBack}>
          <ArrowLeft />
        </BackArrowWrapper>
        <SectionWrapper>
          <Title>{title || titleAlternative}</Title>
          <Subtitle>{subtitle || subtitleAlternative}</Subtitle>
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>{t("offer_requirements")}</SectionTitle>
          <HorizontalDivider />
          <Description
            dangerouslySetInnerHTML={{
              __html: replaceHTMLLineSeparator(
                requirements || requirementsAlternative
              ),
            }}
          />
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>{t("offer_weOffer")}</SectionTitle>
          <HorizontalDivider />
          <Description
            dangerouslySetInnerHTML={{
              __html: replaceHTMLLineSeparator(
                whatDoWeOffer || whatDoWeOfferAlternative
              ),
            }}
          />
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>{t("offer_whoWeAre")}</SectionTitle>
          <HorizontalDivider />
          <Description
            dangerouslySetInnerHTML={{
              __html: replaceHTMLLineSeparator(whoAreWe || whoAreWeAlternative),
            }}
          />
        </SectionWrapper>

        <SectionWrapper>
          <SectionTitle>{t("offer_apply")}</SectionTitle>
          <HorizontalDivider />
          <Description
            dangerouslySetInnerHTML={{
              __html: replaceHTMLLineSeparator(apply || applyAlternative),
            }}
          />
        </SectionWrapper>

        <Breakpoint device={["tablet", "tabletLandscape", "computer"]}>
          <BackgroundWrapper>
            <BlueRectangle row="1" column="4" />
            <BlueRectangle row="2" column="2 / span 2" />
            <BlueRectangle row="3" column="1" />
            <BlueRectangle row="3" column="4" />
            <BlueRectangle row="4" column="2" />
            <BlueRectangle row="4" column="4" />
            <BlueRectangle row="5" column="3" />
            <BlueRectangle row="6" column="4" />
          </BackgroundWrapper>
        </Breakpoint>

        <div>
          <HorizontalDivider />
          <FooterWrapper>
            <ContactInformation>
              <ContactTitle>{i18nContact.t(`oke_title`)}</ContactTitle>
              <ContactDescription>
                {i18nContact.t(`oke_location`)}
              </ContactDescription>
            </ContactInformation>
            <ContactInformation>
              <ContactTitle>{i18nContact.t(`elblag_title`)}</ContactTitle>
              <ContactDescription>
                {i18nContact.t(`elblag_location`)}
              </ContactDescription>
            </ContactInformation>
          </FooterWrapper>
        </div>

        <TransitionWrapper animation={pageChange}>
          {transitionRandomBlocks}
        </TransitionWrapper>
      </Wrapper>
    </>
  ) : (
    <></>
  );
};

export const offerQuery = graphql`
  query querySingleOffer($idPL: String!, $idEN: String!) {
    pl: datoCmsOffer(id: { eq: $idPL }) {
      id
      title
      subtitle
      requirements
      whatDoWeOffer
      whoAreWe
      apply
    }
    en: datoCmsOffer(id: { eq: $idEN }) {
      id
      title
      subtitle
      requirements
      whatDoWeOffer
      whoAreWe
      apply
    }
  }
`;

export default Offers;
